import type { FC } from "react"
import ReactConfetti from "react-confetti"

export type ModalAlertSeverity = "success" | "failed" | "info"

export interface ModalAlertProps {
    options: {
        visible: boolean
        severity?: ModalAlertSeverity
        message?: string
        actionText?: string
        actionClick: () => void
        close?: () => void
    }
}
const ModalAlert: FC<ModalAlertProps> = (props) => {

    const getSeverityTitle = (severity: ModalAlertSeverity) => {
        switch (severity) {
            case "success":
                return "Success"
            case "failed":
                return "Failed"
            case "info":
                return "Info"
        }
    }

    const getSeverityIcon = (severity: ModalAlertSeverity) => {
        switch (severity) {
            case "success":
                return require("../../assets/images/interaction/check-mark.png")
            case "failed":
                return require("../../assets/images/interaction/multiply.png")
            case "info":
                return require("../../assets/images/interaction/info-circle-svgrepo-com.png")
        }
    }
    return (
        <>
            {
                props.options.visible && <div className="z-50 fixed top-0 left-0 w-full h-full backdrop-blur-md bg-white/30 flex items-center justify-center">
                    {
                        props.options.severity === "success" &&
                        <ReactConfetti
                            width={window.innerWidth}
                            height={window.innerHeight}
                        />
                    }
                    <div className="bg-gray-50 dark:bg-gray-700 px-10 py-10 rounded-lg flex flex-col items-center justify-center gap-5 max-w-2xl relative">
                        {
                            props.options.close ? <div className="absolute top-2 right-2">
                                <button type="button" onClick={() => {
                                    if (props.options?.close) {
                                        props.options?.close()
                                    }
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 stroke-red-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div> : null
                        }

                        <img src={getSeverityIcon(props.options?.severity || "failed")} className="h-20 w-auto m-auto" loading="lazy" alt="client logo" width="" height="" />
                        <span className="text-gray-900 dark:text-gray-300 text-2xl font-bold">{getSeverityTitle(props.options?.severity || "failed")}</span>
                        <span className="text-gray-900 dark:text-gray-300 text-sm font-thin text-center">{props.options.message || ""}</span>

                        <button
                            onClick={props.options.actionClick}
                            className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                        >
                            <span className="relative text-base font-semibold text-white">{props.options.actionText ? props.options.actionText : "Done"}</span>
                        </button>
                    </div>

                </div>
            }
        </>

    )
}

export default ModalAlert