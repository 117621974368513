import BestSellingLeadView from "./components/BestSellingLeadView"
import CallToActionView from "./components/CallToActionView"
import FeaturesView from "./components/FeaturesView"
import FullAutomationView from "./components/FullAutomationView"
import HeroSectionView from "./components/HeroSectionView"
import OfferView from "./components/OfferView"
// import TestimonialsView from "./components/TestimonialsView"

const HomeView = () => {
  return (
    <main
      className="space-y-40 mb-40"
    >
      <HeroSectionView />
      <FullAutomationView />
      <BestSellingLeadView />
      <OfferView />
      <FeaturesView />
      {/* <TestimonialsView /> */}
      <CallToActionView />
    </main>
  )
}

export default HomeView