import ContainerView from "../../landing-layout/components/ContainerView"

const OfferView = () => {
  return (
    <div className="text-gray-600 dark:text-gray-300" id="offer-view">
      <ContainerView>
        <div className=" space-y-4 px-6 md:px-0">
          <h2 className="text-center text-2xl font-bold text-gray-800 dark:text-white md:text-4xl">
            MOBILE APPLICATIONS AND DESKTOP APPLICATIONS FOR DIGITAL MARKETERS
          </h2>

        </div>
        <div className='my-8 flex flex-col max-w-2xl mx-auto gap-2 items-center'>
          <p className="text-gray-600 dark:text-gray-300 text-center ">
            Autopilot Pro helps digital marketers by offering powerful mobile and desktop applications designed to automate and streamline marketing processes. Our mobile applications enable marketers to monitor and manage campaigns, track customer interactions, and access real-time analytics from anywhere, ensuring they stay agile and responsive.
          </p>
          <p className="text-gray-600 dark:text-gray-300 text-center ">
            On the desktop side, Autopilot Pro provides robust tools for deeper data analysis, campaign optimization, and content creation, allowing marketers manage their strategies from a centralized platform. By integrating automation into both mobile and desktop applications, Autopilot Pro reduces manual tasks, enhances workflow efficiency, and boosts productivity.
          </p>

        </div>


      </ContainerView>
    </div>
  )
}

export default OfferView