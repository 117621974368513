import ContainerView from "../../landing-layout/components/ContainerView"

const FullAutomationView = () => {
  return (
    <div className="text-gray-600 dark:text-gray-300" id="full-automation">
      <ContainerView>
        <div className=" space-y-4 px-6 md:px-0">
          <h2 className="text-center text-2xl font-bold text-gray-800 dark:text-white md:text-4xl">
            Get Full Automation
          </h2>
        </div>
        <div className='my-8 grid grid-flow-row grid-cols-1 lg:grid-cols-2 gap-2 items-center aspect-auto p-8'>
          <div className="flex flex-col gap-3" >

            <p className="text-gray-600 dark:text-gray-300">
              Our Full Automation Service offers a complete 24/7 solution to streamline your email marketing process. With ready-to-purchase, highly targeted email leads, you can immediately engage with potential clients, increase efficiency and consistent communication. Our system is designed to save time, optimize engagement, and drive results with minimal manual effort, helping you focus on growing your business. Our Full Automation service, powered by Autopilot Pro, is designed to revolutionize how digital marketers manage their workflows and campaigns. By automating repetitive tasks, managing leads, and optimizing customer journeys, Autopilot Pro frees up valuable time for marketers to focus on high-impact strategies
            </p>
            <p className="text-gray-600 dark:text-gray-300">
              Here's how our full automation helps boost your business
            </p>
            <a
             href="/full-automation"
              // href="https://pay.sumup.com/b2c/QIFCBED8"
              className="relative flex h-12 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
            >
              <span className="relative text-base font-semibold text-white dark:text-dark"
              >Check it out</span
              >
            </a>
          </div>
          <div>
            <img className='aspect-square object-cover' src={"https://firebasestorage.googleapis.com/v0/b/autopilot-mobile.appspot.com/o/assets%2Fimages%2Fproducts%2Ffull-automation.jpg?alt=media&token=873b052c-1ae4-4531-a2a6-952bc23ec582"} alt="full automation" />
          </div>
        </div>


      </ContainerView>
    </div>
  )
}

export default FullAutomationView